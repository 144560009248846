import { ReactElement, useEffect, useReducer, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useDebounce } from 'hooks/useDebounce'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { Order, IInterventionsGet, InterventionClass } from '@Arti-zen/package-backoffice'
import { IInterventionList } from '../InterventionList.interfaces'
import { interventionListActions } from 'modules/interventionList/store/store.interventionList.reducer'
import { definitionFields, firstColumn } from './InterventionListTable.definitionFields'

export default function InterventionListTable({
  search = null,
  filterBase = {},
}: Readonly<IInterventionList>): ReactElement {
  const dispatch = useAppDispatch()
  const { artisan, companyIds } = useAppSelector((state) => state.artisan)
  const { interventions, isQueriedAllCompleted } = useAppSelector((state) => state.interventionList)

  // const [noFilter, setNoFilter] = useState(true)
  const [currentSort, setCurrentSort] = useReducer(sortReducer, [
    { key: 'interventionStartDate', order: Order.DESC },
    { key: 'id', order: Order.DESC },
  ])

  const fields = definitionFields()
  const [displayedFields, setDisplayedFields] = useState(fields.map((field) => field.indexName))

  const searchText = useDebounce(search, 500)

  const refresh = () => {
    // if (!filterBase?.status || filterBase.status.length === 0) return setNoFilter(true)
    // setNoFilter(false)

    const newFilters: IInterventionsGet = {
      userIds: companyIds,
      page: 1,
      limit: 15,
      ...filterBase,
      searchText: searchText,
      sort: currentSort,
    }

    dispatch(interventionListActions.fetch(newFilters))
  }

  useEffect(() => {
    refresh()
  }, [searchText, filterBase, currentSort])

  const nextPage = () => {
    if (!isQueriedAllCompleted) {
      dispatch(interventionListActions.fetchNextPage())
    } else {
      return null
    }
  }

  return (
    artisan && (
      <TableInfinite<InterventionClass['meilisearchDoc']>
        definitionFields={fields}
        firstColumn={firstColumn}
        displayedFields={displayedFields}
        setDisplayedFields={setDisplayedFields}
        data={interventions}
        nextPage={nextPage}
        isQueriedAllCompleted={isQueriedAllCompleted}
        onSort={(indexName, order) => setCurrentSort({ key: indexName, order })}
        activeSorts={currentSort}
        displayError={null}
      />
    )
  )
}

const sortReducer = (
  _state,
  action: { key: keyof InterventionClass['meilisearchDoc']; order: Order }
): IInterventionsGet['sort'] => {
  const { key, order } = action

  switch (key) {
    case 'interventionStartDate':
      return [
        { key: 'interventionStartDate', order },
        { key: 'id', order: Order.DESC },
      ]
    case 'id':
      return [
        { key: 'id', order },
        { key: 'interventionStartDate', order: Order.DESC },
      ]
    default:
      return [
        { key, order },
        { key: 'interventionStartDate', order: Order.DESC },
        { key: 'id', order: Order.DESC },
      ]
  }
}
