import { ReactElement, useEffect, useReducer, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useStore'
import { useDebounce } from 'hooks/useDebounce'
import { quotationListActions } from 'modules/quotationList/store/store.quotationList.reducer'
import { IQuotationList } from '../QuotationList.interfaces'
import { definitionFields, firstColumn } from './QuotationListTable.definitionFields'
import TableInfinite from 'components/table/infinite/InfiniteTable'
import { IQuotationsGet, QuotationClass, Order } from '@Arti-zen/package-backoffice'
import { useNavigate } from 'react-router-dom'

export default function QuotationListTable({ search = null, filterBase = {} }: Readonly<IQuotationList>): ReactElement {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { artisan, companyIds } = useAppSelector((state) => state.artisan)
  const { quotations, isQueriedAllCompleted } = useAppSelector((state) => state.quotationList)

  const [noFilter, setNoFilter] = useState(true)
  const [currentSort, setCurrentSort] = useReducer(sortReducer, [
    { key: 'creationDate', order: Order.DESC },
    { key: 'number', order: Order.DESC },
  ])

  const fields = definitionFields(navigate)
  const [displayedFields, setDisplayedFields] = useState(fields.map((field) => field.indexName))

  const searchText = useDebounce(search, 500)

  const refresh = () => {
    if (!filterBase?.status || filterBase.status.length === 0) return setNoFilter(true)
    setNoFilter(false)

    const newFilters: IQuotationsGet = {
      userIds: companyIds,
      page: 1,
      limit: 15,
      ...filterBase,
      searchText: searchText,
      sort: currentSort,
    }

    dispatch(quotationListActions.fetch(newFilters))
  }

  useEffect(() => {
    refresh()
  }, [searchText, filterBase, currentSort])

  const nextPage = () => {
    if (!isQueriedAllCompleted) {
      dispatch(quotationListActions.fetchNextPage())
    } else {
      return null
    }
  }

  return (
    <TableInfinite<QuotationClass['meilisearchDoc']>
      definitionFields={fields}
      firstColumn={firstColumn}
      displayedFields={displayedFields}
      setDisplayedFields={setDisplayedFields}
      data={noFilter ? [] : quotations}
      nextPage={nextPage}
      isQueriedAllCompleted={noFilter || isQueriedAllCompleted}
      onSort={(indexName, order) => setCurrentSort({ key: indexName, order })}
      activeSorts={currentSort}
      displayError={noFilter ? 'Sélectionner au moins un statut de devis.' : null}
    />
  )
}

const sortReducer = (
  _state,
  action: { key: keyof QuotationClass['meilisearchDoc']; order: Order }
): IQuotationsGet['sort'] => {
  const { key, order } = action

  switch (key) {
    case 'creationDate':
      return [
        { key: 'creationDate', order },
        { key: 'number', order: Order.DESC },
      ]
    case 'number':
      return [
        { key: 'number', order },
        { key: 'creationDate', order: Order.DESC },
      ]
    default:
      return [
        { key, order },
        { key: 'creationDate', order: Order.DESC },
        { key: 'number', order: Order.DESC },
      ]
  }
}
