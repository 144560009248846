import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import * as thunks from './store.artisan.thunks'
import { IArtisanState } from './store.artisan.interfaces'
import { DateTime } from 'luxon'

const initialState: IArtisanState = {
  artisan: null,
  children: [],
  companyIds: [],
  dashboard: {
    dateStart: DateTime.now().setZone('Europe/Paris').minus({ year: 1 }).startOf('month'),
    dateEnd: DateTime.now().setZone('Europe/Paris').endOf('month'),
    interventions: [],
    quotations: [],
    invoices: [],
  },
  factureList: [],
}

const artisanSlice = createSlice({
  name: 'artisan',
  initialState,
  reducers: {
    init() {
      return initialState
    },
    setDateStart(state, action: PayloadAction<DateTime>) {
      state.dashboard.dateStart = action.payload.setZone('Europe/Paris').startOf('day')
    },
    setDateEnd(state, action: PayloadAction<DateTime>) {
      state.dashboard.dateEnd = action.payload.setZone('Europe/Paris').endOf('day')
    },
  },
  extraReducers: (builder) => thunks.extraReducers(builder),
})

export const artisanActions = { ...artisanSlice.actions, ...thunks.asyncActions }
export default artisanSlice.reducer
